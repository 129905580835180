import { render, staticRenderFns } from "./ErrorMessage.vue?vue&type=template&id=078f0b60&"
import script from "./ErrorMessage.vue?vue&type=script&lang=js&"
export * from "./ErrorMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('078f0b60')) {
      api.createRecord('078f0b60', component.options)
    } else {
      api.reload('078f0b60', component.options)
    }
    module.hot.accept("./ErrorMessage.vue?vue&type=template&id=078f0b60&", function () {
      api.rerender('078f0b60', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8mp1JD3zI6y5T/JS/comp/ErrorMessage.vue"
export default component.exports